import React, { useState } from 'react';
import css from './FAQGuest.module.css';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/svg/arrowUp.svg';
import classNames from 'classnames';
export default function FAQGuest() {
  const [value, setValue] = useState({
    showValue: false,
    showValue1: false,
    showValue2: false,
    showValue3: false,
    showValue4: false,
    showValue5: false,
    showValue6: false,
    showValue7: false,
    showValue8: false,
    showValue9: false,
    showValue10: false,
    showValue11: false,
    showValue12: false,
    showValue13: false,
    showValue14: false,
  });

  return (
    <div className={css.boxContainer}>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: !value.showValue,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>What is Harbour Hound?</span>
          </h3>
          {value.showValue ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue,
            })}
          >
            <p>
              Harbour Hound is an online platform for searching, listing and renting marina berths,
              private pontoons and swing moorings.
            </p>
            <p>
              Our goal is to unlock the thousands of vacant berths along Australia coastline and
              rivers for the boating community, offer new and unique mooring opportunities and
              simplify the berth booking process.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue1,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: !value.showValue1,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>How does Harbour Hound work?</span>
          </h3>
          {value.showValue1 ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue1 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue1,
            })}
          >
            <ul className={css.bulletlist}>
              <li>
                Step 1: Boat owner searches, finds and books a berth, private pontoon or mooring.
              </li>
              <li>Step 2: Berth owner confirms the reservation.</li>
              <li>
                Step 3: Boat owner is charged by Harbour Hound. This amount includes the rental fee
                and Harbour Hound's service charge (guest charge). The funds are held by Harbour
                Hound and are not yet released to the berth owner (host).
              </li>
              <li>
                Step 4: Boat owner receives approval to use the berth, private pontoon or mooring.{' '}
              </li>
              <li>Step 5: Boat owner uses the berth, private pontoon or mooring. </li>
              <li>
                Step 6: Payment is made to the berth owner less Harbour Hounds service charge (host
                charge).
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue2,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: !value.showValue2,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>How much does it cost to sign up?</span>
          </h3>
          {value.showValue2 ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue2 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue2,
            })}
          >
            <p>
              It is completely free to join Harbour Hound as host (berth owner) or guest (boat
              owner).
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue3,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: !value.showValue3,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>How does Harbour Hound make money?</span>
          </h3>
          {value.showValue3 ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue3 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue3,
            })}
          >
            <p>
              Harbour Hound charges boat owners (guests) a small service fee upon each successful
              berth booking. This is typically 10% of the total booking amount and is to keep our
              lights on and our staff paid. Harbour Hound may also charge the berth owner (hosts) a
              service fee on each booking.
            </p>
            <p>
              <span>
                10% of total service fees received by Harbour Hound is donated to Volunteer Marine
                Rescue
                {/* Service QLD or NSW depending on where each booking is made. */}
              </span>
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue4,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: !value.showValue4,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>Can I cancel my booking?</span>
          </h3>
          {value.showValue4 ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue4 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue4,
            })}
          >
            <p>
              Berth owners set their Cancellation policy when creating a listing - they can choose
              to offer a full refund if the cancellation is made more than 14 days before the
              booking commences or no refund for cancellation after payment is made. We recommend
              checking the cancellation policy on the listing prior to booking.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue5,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: !value.showValue5,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              Does Harbour Hound monitor the quality of berth listings?
            </span>
          </h3>
          {value.showValue5 ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue5 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue5,
            })}
          >
            <p>
              Yes, Harbour Hound verifies all berth listings to ensure they are as accurate as
              possible. We encourage all berth owners to provide as much information as possible
              when creating a listing to ensure the actual berth is a true representation of the
              listing. All booking fees paid by the boat owner (guest) are also held by Harbour
              Hound and won't be released to the berth owner (host) until the second day of the
              booking. This is to ensure the space works out for both the guest and host.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue6,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: !value.showValue6,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>How will I know when my booking is confirmed?</span>
          </h3>
          {value.showValue6 ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue6 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue6,
            })}
          >
            <p>
              You will receive an email confirming that the berth owner (host) has approved your
              booking. You can also view your online profile which will display any active booking
              requests and its status.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue7,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: !value.showValue7,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>What payment methods does Harbour Hound accept?</span>
          </h3>
          {value.showValue7 ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue7 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue7,
            })}
          >
            <p>Harbour Hound accepts debit or credit cards for booking payments.</p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue8,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: !value.showValue8,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>Where can I find my booking invoice?</span>
          </h3>
          {value.showValue8 ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue8 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue8,
            })}
          >
            <p>
              Your booking invoice will be emailed to you upon booking confirmation. 
              {/* For any other
              invoicing matters please contact Harbour Hound support on 0456 240 164. */}
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue9,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: !value.showValue9,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              How do I know if my boat or jet ski will fit in the berth?
            </span>
          </h3>
          {value.showValue9 ? <ArrowUp className={css.icon} /> : <ArrowDown className={css.icon} />}
        </div>
        {value.showValue9 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue9,
            })}
          >
            <p>
              Each berth listing will include the dimensions of the berth such as total acceptable
              length, beam and draft. We recommend reviewing these measurements prior to booking a
              berth to ensure your vessel will fit in the berth.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue10,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: !value.showValue10,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>I am having an issue with my booking. What do I do?</span>
          </h3>
          {value.showValue10 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue10 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue10,
            })}
          >
            {/* <p>Please feel free to contact Harbour Hound support on 0456 240 164.</p> */}
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue11,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: !value.showValue11,
              showValue12: false,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              When I view a listing there is an option to pay monthly or upfront (including
              discount). What is this?
            </span>
          </h3>
          {value.showValue11 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue11 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue11,
            })}
          >
            <p>
              While berth rental is typically paid per month, we recognise some boat owners (guests)
              may prefer to pay the total booking amount upfront. We therefore wanted to provide
              hosts with the option to offer an upfront payment discount (%) to boat owners who are
              willing to pay the total booking amount upfront.
            </p>
            <p>
              Berth owners (hosts) will typically only offer a discount for bookings longer than One
              (1) month.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue12,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: !value.showValue12,
              showValue13: false,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              Are transactions on the Harbour Hound website secure? What payment processing system
              does Harbour Hound use?
            </span>
          </h3>
          {value.showValue12 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue12 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue12,
            })}
          >
            <p>
              All transactions processed on the Harbour Hound website are 100% secure for berth and
              boat owners. Harbour Hound uses online payments processor STRIPE for all payments.
              STRIPE is adopted by various online bookings platforms including <b>Airbnb & Uber.</b>
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue13,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: !value.showValue13,
              showValue14: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              I need to contact the berth owner. How do I do this?
            </span>
          </h3>
          {value.showValue13 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue13 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue13,
            })}
          >
            <p>
              Once the booking request is confirmed you can use Harbour Hound’s internal messaging
              to contact the berth owner directly. Send a message by accessing your inbox ‘As a
              Berth Guest’ and clicking on the booking.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue14,
          })}
          onClick={() =>
            setValue(value => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
              showValue13: false,
              showValue14: !value.showValue14,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              A berth listing is requesting my vessel details and Proof of Insurance document. Why?
            </span>
          </h3>
          {value.showValue14 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue14 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue14,
            })}
          >
            <p>
              Harbour Hound includes listings for berths that are owned and/or managed by commercial
              marinas. This is shown by the watermark on the cover photo of the listing.
            </p>
            <p>
              In many cases the marina will require a minimum amount of information on your vessel
              including its name, registration, length, beam and other details. They may also
              require a copy of your current insurance to approve your booking request.
            </p>
            <p>
              We recommend uploading your Proof of Insurance and keeping it up to date by editing
              your profile. You can also add and edit all of your personal and vessel information so
              that marinas can approve your booking request instantly.
            </p>
            <p>
              All of your personal, vessel and insurance details are kept 100% secure on the Harbour
              Hound platform and are only sent to the marina when you submit a booking request.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
