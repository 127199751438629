import React, { useState } from "react";
import css from "./FAQHost.module.css";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/arrowUp.svg";
import classNames from "classnames";
export default function FAQHost() {
  const [value, setValue] = useState({
    showValue: false,
    showValue1: false,
    showValue2: false,
    showValue3: false,
    showValue4: false,
    showValue5: false,
    showValue6: false,
    showValue7: false,
    showValue8: false,
    showValue9: false,
    showValue10: false,
    showValue11: false,
    showValue12: false,
  });
  return (
    <div className={css.boxContainer}>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        {/* <div className={css.headingBox} onClick={() => setShowValue(!showValue)}> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: !value.showValue,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>What is Harbour Hound?</span>
          </h3>
          {value.showValue ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue,
            })}
          >
            <p>
              Harbour Hound is an online platform for searching, listing and
              renting marina berths, private pontoons and swing moorings.
            </p>
            <p>
              Our goal is to unlock the thousands of vacant berths along
              Australia coastline and rivers for the boating community, offer
              new and unique mooring opportunities and simplify the berth
              booking process.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue1,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: !value.showValue1,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>How does Harbour Hound work?</span>
          </h3>
          {value.showValue1 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue1 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue1,
            })}
          >
            <ul className={css.bulletlist}>
              <li>
                Step 1: Boat owner searches, finds and books a berth, private
                pontoon or mooring.
              </li>
              <li>Step 2: Berth owner confirms the reservation.</li>
              <li>
                Step 3: Boat owner is charged by Harbour Hound. This amount
                includes the rental fee and Harbour Hound's service charge
                (guest charge).
              </li>
              <li>
                Step 4: Boat owner receives approval to use the berth, private
                pontoon or mooring.{" "}
              </li>
              <li>
                Step 5: Boat owner uses the berth, private pontoon or mooring.{" "}
              </li>
              <li>
                Step 6: Payment is made to the berth owner less Harbour Hounds
                service charge (host charge).
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue2,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: !value.showValue2,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              How much does it cost to sign up?
            </span>
          </h3>
          {value.showValue2 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue2 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue2,
            })}
          >
            <p>
              It is completely free to join Harbour Hound as host (berth owner)
              or guest (boat owner).
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue3,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: !value.showValue3,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              How does Harbour Hound make money?
            </span>
          </h3>
          {value.showValue3 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue3 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue3,
            })}
          >
            <p>
              Harbour Hound charges berth owners (hosts) a small service fee
              upon each successful berth booking. This is typically 7% of the
              total booking amount and is to keep our lights on and our staff
              paid. Harbour Hound may also charge the boat owner (guests) a
              service fee on each booking.
            </p>
            <p>
              <span>
                10% of total service fees received by Harbour Hound is donated
                to Volunteer Marine Rescue
                {/* Service QLD or NSW depending on where each booking is made. */}
              </span>
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue4,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: !value.showValue4,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              What are the benefits of listing my berth or pontoon?
            </span>
          </h3>
          {value.showValue4 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue4 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue4,
            })}
          >
            <p>
              Harbour Hound is the safest and easiest way to make money from
              your vacant marina berth or pontoon at home. Here are some of the
              benefits:
            </p>
            <ul className={css.bulletlist}>
              <li>
                Its free to list. We only charge berth owners (hosts) a small
                service fee upon each successful booking, typically 7% of the
                booking amount. All other service fees are paid by the boat
                owner (guest).{" "}
              </li>
              <li>
                Listing is simple. Your berth can be online and making you money
                in as little as 10 minutes.
              </li>
              <li>
                We handle all bookings, payment and marketing of your berth
              </li>
              <li>
                Unlike renting your house, no access inside your home is
                required meaning greater security and peace of mind. We
                recommend providing side gate access/other if possible if you
                are renting your private pontoon.{" "}
              </li>
              <li>
                You set the rules including pricing, availability of your berth{" "}
              </li>
              <li>
                Harbour Hound is an opportunity to connect with the boating
                community. We encourage people to list their berth to connect
                with other enthusiasts & grow their network.
              </li>
              <li>
                All boat owners/guests are 100% verified by Harbour Hound.
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue5,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: !value.showValue5,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              How do I list my berth or pontoon?
            </span>
          </h3>
          {value.showValue5 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue5 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue5,
            })}
          >
            <p>To list your berth or pontoon all you need is:</p>
            <ul className={css.bulletlist}>
              <li>
                High quality photos: We recommend adding at least 3 photos to
                showcase your berth or pontoon. This should be of the space
                itself and any supporting details such as access, street parking
                etc.
              </li>
              <li>
                Dimensions: You’ll need the dimensions of your berth including
                length and beam. This helps potential boat owners determine if
                they can fit in your berth.
              </li>
              <li>
                Features of your space, such as security, access and distance
                from public transport or amenities.
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue6,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: !value.showValue6,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              How much can I lease my berth or pontoon for?
            </span>
          </h3>
          {value.showValue6 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue6 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue6,
            })}
          >
            <p>
              The value of your berth or pontoon depends on a number of factors
              not limited to length. Location, availability, proximity to open
              waters, profender (yes/no) can all play a role. As a guide, we
              recommend researching nearby marina berth costs and listing your
              space at a competitive rate.
              {/* Feel free to give us a call on 0456 240 164
              for assistance on berth rates. */}
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue7,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: !value.showValue7,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              How do I get paid for a booking?
            </span>
          </h3>
          {value.showValue7 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue7 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue7,
            })}
          >
            <p>
              Payments are sent to the bank account you have registered in your
              profile. We strongly recommend registering your account details
              accurately to ensure you can receive automatic payments.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue8,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: !value.showValue8,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              How do I know when someone has rented my berth?
            </span>
          </h3>
          {value.showValue8 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue8 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue8,
            })}
          >
            <p>
              Below is an overview of what happens when someone requests to book
              your berth or pontoon:
            </p>
            <ul className={css.bulletlist}>
              <li>
                When a boat owner (guest) submits a booking request, we send you
                an email and SMS, asking if you accept the booking. You can then
                accept the booking request you received from the boat owner.
              </li>
              <li>
                Once the booking has been accepted the boat owner (guest) gets
                billed to lock in the booking.
              </li>
              <li>
                If the berth has secure access we suggest using Harbour Hound's
                built-in messaging service to arrange any handover of any access
                device (swipe card, key, remote etc).
              </li>
              <li>
                On the second day of the booking your payout will be processed.
                The reason we wait until the second day is to make sure the
                space works out for the boat owner (guest), and for you, the
                berth owner (host).
              </li>
            </ul>
            {/* <p>
              If you have any more questions please don't hesitate to contact us at 0456 240 164.
            </p> */}
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue9,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: !value.showValue9,
              showValue10: false,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              I am a marina operator. Can I list my vacant berths for rent on
              Harbour Hound?
            </span>
          </h3>
          {value.showValue9 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue9 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue9,
            })}
          >
            <p>
              Yes! Harbour Hound encourages commercial marinas to list any berth
              vacancy on the platform.
              {/* To discuss longer term partnerships please contact Harbour Hound on 0456 240
              164. */}
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue10,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: !value.showValue10,
              showValue11: false,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              When listing my berth there is an option to offer a discount (%).
              What is this for?
            </span>
          </h3>
          {value.showValue10 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue10 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue10,
            })}
          >
            <p>
              While berth rental is typically paid per month, we recognise some
              berth owners (hosts) prefer the total booking amount be paid
              upfront. We therefore wanted to provide hosts with the option to
              offer an upfront payment discount (%) to encourage upfront payment
              of berth rental fees.
            </p>
            <p>
              When a boat owner (guest) views your listing they can then choose
              to pay monthly (no discount) or the total amount upfront
              (including the discount set by you).
            </p>
            <p>
              We recommend offering discounts only for bookings longer than One
              (1) month.
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue11,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: !value.showValue11,
              showValue12: false,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              Are transactions on the Harbour Hound website secure? What payment
              processing system does Harbour Hound use?
            </span>
          </h3>
          {value.showValue11 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue11 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue11,
            })}
          >
            <p>
              All transactions processed on the Harbour Hound website are 100%
              secure for berth and boat owners. Harbour Hound uses online
              payments processor STRIPE for all payments. STRIPE is adopted by
              various online bookings platforms including <b>Airbnb & Uber.</b>
            </p>
          </div>
        )}
      </div>
      <div className={css.card}>
        {/* <div className={css.heading}>What is Harbour Hound?</div> */}
        <div
          className={classNames(css.headingBox, {
            [css.headingBoxExpand]: value.showValue12,
          })}
          onClick={() =>
            setValue((value) => ({
              showValue: false,
              showValue1: false,
              showValue2: false,
              showValue3: false,
              showValue4: false,
              showValue5: false,
              showValue6: false,
              showValue7: false,
              showValue8: false,
              showValue9: false,
              showValue10: false,
              showValue11: false,
              showValue12: !value.showValue12,
            }))
          }
        >
          <h3>
            <span className={css.heading}>
              I need to contact my guest. How do I do this?
            </span>
          </h3>
          {value.showValue12 ? (
            <ArrowUp className={css.icon} />
          ) : (
            <ArrowDown className={css.icon} />
          )}
        </div>
        {value.showValue12 && (
          <div
            className={classNames(css.para, {
              [css.paraExpand]: value.showValue12,
            })}
          >
            <p>
              Once you confirm the booking request you can use Harbour Hound’s
              internal messaging to contact your guest directly. Send a message
              by accessing your inbox ‘As a Berth Host’ and clicking on the
              booking.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
