import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;
  const history = useHistory();
  const isLandingPage = history?.location?.pathname === '/';
  console.log(6666, isLandingPage, history?.location?.pathname);

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  return (
    // <>
    //   {isLandingPage ? (
    //     <div className={css.wrapper}>
    //       <video className={css.previewVideo} loop autoPlay muted>
    //         {/* Source of the video */}
    //         <source
    //           src={`https://harbour-hound.s3.ap-southeast-2.amazonaws.com/Video+10-4-2024%2C+8+38+15%E2%80%AFpm.mov`}
    //           // src={`https://fleggs-dev.s3.eu-west-2.amazonaws.com/videos/Ness+2.mov-1721057072688.quicktime`}
    //           // type="video/mp4"
    //         />
    //         {/* Text to be displayed if the browser does not support the video */}
    //         Your browser does not support the video tag.
    //       </video>
    //       <div className={css.desc}>
    //         {hasHeaderFields ? (
    //           <header className={defaultClasses.sectionDetails}>
    //             <Field data={title} className={defaultClasses.title} options={fieldOptions} />
    //             <Field
    //               data={description}
    //               className={defaultClasses.description}
    //               options={fieldOptions}
    //             />
    //             <Field
    //               data={callToAction}
    //               className={defaultClasses.ctaButton}
    //               options={fieldOptions}
    //             />
    //           </header>
    //         ) : null}
    //       </div>
    //     </div>
    //   ) : (
    //     <>
    //       <SectionContainer
    //         id={sectionId}
    //         className={className}
    //         rootClassName={classNames(rootClassName || css.root)}
    //         appearance={appearance}
    //         options={fieldOptions}
    //       >
    //         {hasHeaderFields ? (
    //           <header className={defaultClasses.sectionDetails}>
    //             <Field data={title} className={defaultClasses.title} options={fieldOptions} />
    //             <Field
    //               data={description}
    //               className={defaultClasses.description}
    //               options={fieldOptions}
    //             />
    //             <Field
    //               data={callToAction}
    //               className={defaultClasses.ctaButton}
    //               options={fieldOptions}
    //             />
    //           </header>
    //         ) : null}
    //       </SectionContainer>
    //     </>
    //   )}
    // </>
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
